import Column from '../../components/Column'
import Text from '../../components/Text'
import Row from '../../components/Row'
import Header from '../../components/Header'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { redirectIfNotLogged } from '../../global/Token'
import Button from '../../components/Button'
import ApiRequest, { Response } from '../../global/Api'
import Loading from '../../components/Loading'
import { styleguide } from '../../global/Theme'
import Popup from '../../components/Popup'
import MenuBottom from '../../components/MenuBottom'
import Image from '../../components/Image'

import ImageExample from '../../assets/img/logo-amazon.svg'
import { Helmet } from 'react-helmet'

export default function Academy(){

	const navigate = useNavigate()

	const [popup, setPopup] = useState({} as Response)

	// useEffect(() => {



	// }, [])

	const classesList = [
		{
			videoName: 'SEJA BEM VINDO',
			videoComponent: <>
				<div dangerouslySetInnerHTML={{ __html: '<div id="vid_676af79455ea8db1a1f4b313" style="position: relative; width: 100%; padding: 177.77777777777777% 0 0;"> <img id="thumb_676af79455ea8db1a1f4b313" src="https://images.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/676af79455ea8db1a1f4b313/thumbnail.jpg" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail"> <div id="backdrop_676af79455ea8db1a1f4b313" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%; "></div> </div>' }} />
				<Helmet>
					<script type="text/javascript" id="scr_676af79455ea8db1a1f4b313"> var s=document.createElement("script"); s.src="https://scripts.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/676af79455ea8db1a1f4b313/player.js", s.async=!0,document.head.appendChild(s); </script>
				</Helmet>
			</>
		},
		{
			videoName: 'COMO CRIAR A SUA CONTA',
			videoComponent: <>
				<div dangerouslySetInnerHTML={{ __html: '<div id="vid_676af90355ea8db1a1f4b37d" style="position: relative; width: 100%; padding: 177.77777777777777% 0 0;"> <img id="thumb_676af90355ea8db1a1f4b37d" src="https://images.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/676af90355ea8db1a1f4b37d/thumbnail.jpg" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail"> <div id="backdrop_676af90355ea8db1a1f4b37d" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%; "></div> </div>' }} />
				<Helmet>
					<script type="text/javascript" id="scr_676af90355ea8db1a1f4b37d"> var s=document.createElement("script"); s.src="https://scripts.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/676af90355ea8db1a1f4b37d/player.js", s.async=!0,document.head.appendChild(s); </script>
				</Helmet>
			</>
		},
		{
			videoName: 'GERENCIAMENTO DE BANCA',
			videoComponent: <>
				<div dangerouslySetInnerHTML={{ __html: '<div id="vid_676b012f62eaeabb1bfd9527" style="position: relative; width: 100%; padding: 177.77777777777777% 0 0;"> <img id="thumb_676b012f62eaeabb1bfd9527" src="https://images.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/676b012f62eaeabb1bfd9527/thumbnail.jpg" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail"> <div id="backdrop_676b012f62eaeabb1bfd9527" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%; "></div> </div>' }} />
				<Helmet>
					<script type="text/javascript" id="scr_676b012f62eaeabb1bfd9527"> var s=document.createElement("script"); s.src="https://scripts.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/676b012f62eaeabb1bfd9527/player.js", s.async=!0,document.head.appendChild(s); </script>
				</Helmet>
			</>
		},
		{
			videoName: 'COMO USAR O APLICATIVO',
			videoComponent: <>
				<div dangerouslySetInnerHTML={{ __html: '<div id="vid_676b034b647fbcbaec7428f5" style="position: relative; width: 100%; padding: 174.0406320541761% 0 0;"> <img id="thumb_676b034b647fbcbaec7428f5" src="https://images.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/676b034b647fbcbaec7428f5/thumbnail.jpg" style="position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; display: block;" alt="thumbnail"> <div id="backdrop_676b034b647fbcbaec7428f5" style=" -webkit-backdrop-filter: blur(5px); backdrop-filter: blur(5px); position: absolute; top: 0; height: 100%; width: 100%; "></div> </div>' }} />
				<Helmet>
					<script type="text/javascript" id="scr_676b034b647fbcbaec7428f5"> var s=document.createElement("script"); s.src="https://scripts.converteai.net/427a2ce7-0558-4ecd-8876-c0fd21d0cea1/players/676b034b647fbcbaec7428f5/player.js", s.async=!0,document.head.appendChild(s); </script>
				</Helmet>
			</>
		},
	]

	const classes = classesList.map((classe) => <Column
		extraStyles={{
			margin: `${styleguide.space.small} 0`,
		}}
	>
		<Text
			textoption='subtitle'
			align='left'
			extraStyles={{
				margin: `${styleguide.space.small} 5%`,
				fontWeight: 600,
				textAlign: 'center'
			}}
		>
			{ classe.videoName }
		</Text>
		{ classe.videoComponent }
		{/* <Row
			extraStyles={{
				flexWrap: 'nowrap',
				justifyContent: 'flex-start',
				overflowX: 'auto',
				scrollbarWidth: 'none',
				msOverflowStyle: 'none',
				WebkitOverflowScrolling: 'touch'
			}}
		>
			<Image src={ImageExample} />
			<Image src={ImageExample} />
			<Image src={ImageExample} />
			<Image src={ImageExample} />
			<Image src={ImageExample} />
		</Row> */}
	</Column>)

	return (
		<Column
			key='homeMain'
			extraStyles={{
				margin: '0 auto'
			}}
		>
			<Header />

			<Column
				extraStyles={{
					width: '95%',
					marginTop: styleguide.space.big,
					marginBlock: styleguide.space.big,
				}}
			>
				<Text
					textoption='title'
				>
					<i>Academy</i>
				</Text>
				<Text>
					Aprenda a usar o app da melhor forma para copiar entradas dos <b>melhores analistas do mercado!</b>
				</Text>
			</Column>

			{ classes }

			<MenuBottom />
			<Popup data={popup} />
		</Column>
	)

}
